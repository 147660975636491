import { defineStore } from "pinia";

export const useGlobalStore = defineStore("globalStore", {
  state: () => ({
    brandApprovalAccessToken: null, // From two ui apps
    customerId: null as string | null,
  }),
  actions: {
    setGlobalData(customerId: string) {
      this.customerId = customerId;
    },
  },
});
