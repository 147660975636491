/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomerData = {
  /**
   * Trading name of the customer
   */
  trading_name?: string | null;
  /**
   * Company description of the customer
   */
  company_description?: string | null;
  /**
   * Website URL of the customer
   */
  website_url?: string | null;
  /**
   * Contact name for the customer
   */
  contact_name?: string | null;
  /**
   * Contact email for the customer
   */
  contact_email?: string | null;
  /**
   * The trading model of the company
   */
  company_model?: CustomerData.company_model;
  /**
   * Whether the customer reveals discount to their consumers or not
   */
  reveal_discount?: boolean;
  /**
   * Whether the customer trades in crypto
   */
  crypto?: boolean;
  /**
   * What use case the customer belongs to
   */
  use_case?: CustomerData.use_case;
  /**
   * What other use case the customer belongs to
   */
  other_use_case?: string | null;
  /**
   * What kind of access to a product a customer gives to a consumer
   */
  access?: CustomerData.access;
  /**
   * How does a customer market their platform
   */
  marketing?: CustomerData.marketing;
  /**
   * How much volume does a customer project that they will order for a product
   */
  volume?: number | null;
  /**
   * What range of values do consumers order from this customer
   */
  gift_card_size?: Array<"<50" | "50-250" | "250>"> | null;
  /**
   * How this customer's consumers use their product
   */
  gift_card_use?: string | null;
  /**
   * File links to the customer's UX journey
   */
  user_experience_file_links?: Array<string> | null;
  /**
   * Does this customer market to externally facing audiences
   */
  external_marketing?: boolean | null;
  /**
   * File links to the customer's external marketing
   */
  external_marketing_file_links?: Array<string> | null;
  /**
   * Any additional notes the customer is sending to the product approver
   */
  additional_notes?: string | null;
  /**
   * Links to files that the customer has uploaded for the product approver
   */
  additional_notes_file_links?: Array<string> | null;
  /**
   * The launch date of the campaign
   */
  campaign_launch_date?: string | null;
  /**
   * List of contact emails
   */
  contact_emails?: Array<string> | null;
  /**
   * How many clicks on the customer website are expected?
   */
  expected_number_of_impressions?: number | null;
  /**
   * Who is the customer's target audience?
   */
  target_audience?: string | null;
  /**
   * Description of the user experience.
   */
  user_experience_description?: string | null;
  /**
   * URL to give the users an option to link to an example of their user experience
   */
  user_experience_file_url?: string | null;
};

export namespace CustomerData {
  /**
   * The trading model of the company
   */
  export enum company_model {
    B2B = "B2B",
    B2C = "B2C",
    B2B2C = "B2B2C",
    NOT_SOFTWARE = "NOT_SOFTWARE",
  }

  /**
   * What use case the customer belongs to
   */
  export enum use_case {
    EMPLOYEE_BENEFIT = "EMPLOYEE_BENEFIT",
    EMPLOYEE_REWARDS_RECOGNITION = "EMPLOYEE_REWARDS_RECOGNITION",
    GIFT_CARD_MALL = "GIFT_CARD_MALL",
    OTHER = "OTHER",
    RESELLER = "RESELLER",
    SELF_SERVE = "SELF_SERVE",
    MARKETING_RESEARCH_PROMOTION = "MARKETING_RESEARCH_PROMOTION",
    WASHOUT_LOYALTY_MEMBER_BENEFITS = "WASHOUT_LOYALTY_MEMBER_BENEFITS",
    CASHOUT_LOYALTY_MEMBER_BENEFITS = "CASHOUT_LOYALTY_MEMBER_BENEFITS",
  }

  /**
   * What kind of access to a product a customer gives to a consumer
   */
  export enum access {
    PUBLIC_PROGRAM = "PUBLIC_PROGRAM",
    OPEN_PROGRAM = "OPEN_PROGRAM",
    CLOSED_PROGRAM = "CLOSED_PROGRAM",
  }

  /**
   * How does a customer market their platform
   */
  export enum marketing {
    YES = "YES",
    NO = "NO",
  }
}
