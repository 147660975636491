import { defineStore } from "pinia";
import { ProductCodeToNameMap } from "../utils/types";

export const useProductStore = defineStore("productStore", {
  state: () => ({
    productCodesAndNames: null as ProductCodeToNameMap | null,
  }),
});

export function getProductName(
  productCodesAndNames: ProductCodeToNameMap | null | undefined,
  productCode: string
): string {
  if (
    !productCodesAndNames ||
    !productCodesAndNames.hasOwnProperty(productCode)
  ) {
    return productCode;
  }

  return productCodesAndNames[productCode];
}
